import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { BrandService } from 'src/app/modules/core/services/brand.service';
import { SettingsService } from 'src/app/modules/core/services/settings.service';

import { AppFacade } from 'src/app/modules/ngrx-store/app/app.facade';

import { BrandMapItem } from 'src/app/modules/shared/types/brand';
import { UnsubscribeOnDestroy } from 'src/app/modules/shared/utilities/unsubscribe-on-destroy';

@Component({
	selector: 'cp-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends UnsubscribeOnDestroy implements OnInit {
	@Output() hamburgerClicked = new EventEmitter<MouseEvent>();

	@Input() isMyAccountEnabled: boolean = true;

	userInfo$ = this.appFacade.userInfo$;

	brand?: BrandMapItem;

	logoSrc?: string;

	myAccountUrl: string | undefined;

	constructor(
		private brandService: BrandService,
		private appFacade: AppFacade,
		private settingsService: SettingsService,
		private router: Router
	) {
		super();

		this.settingsService.getSettings().pipe(
			this.takeUntilDestroyed()
		).subscribe(settings => {
			const brand = this.brandService.getBrand();

			this.myAccountUrl = settings.brandConfigs[brand.name].logoutUrl;
		});
	}

	ngOnInit(): void {
		this.brand = this.brandService.getBrand();
		this.logoSrc = this.brandService.getBrandImage();
	}
}